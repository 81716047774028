<template>
    <div class="addRounds-container">
        <div class="addRounds-title">
            <div class="back-router" @click="goPage">
                <i class="el-icon-back"></i>
                <div>Back</div>
            </div>
            <div class="addRounds-title-name">Add Rounds</div>
        </div>
        <div class="add-new-round" @click="addRound">
            <i class="el-icon-plus"></i>
            <div class="add-from-list">Add new round</div>
        </div>
        <div id="form-list">
            <el-table :data="roundsList" style="width: 100%" :border="true">
                <el-table-column label="Round" width="100" align="center">
                    <template slot-scope="scope">{{scope.$index+1}}</template>
                </el-table-column>
                <el-table-column prop="price" label="Price" width="100" align="center"></el-table-column>
                <el-table-column prop="supply" label="Supply" width="100" align="center"></el-table-column>
                <el-table-column prop="maxmint" width="400" label="Maximum number of mints per person" align="center"></el-table-column>
                <el-table-column prop="starttime" width="400" label="Data(UTC)" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.starttime}}</span>
                        <span> ~ </span>
                        <span>{{scope.row.endtime}}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
const wallet = require('../../../web3/common/wallet.js');
export default {
    data() {
        return {
            baseUrlData: '',
            roundsList: [],
            seriesid: '',
            querytokenaddress: '',
        };
    },
    computed: {
        mapState() {
            return this.$store.getters.walletInfo
        },

    },
    watch: {
        $route: {
            handler: function() {
                this.seriesid = this.$route.query.id
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.baseUrlData = localStorage.getItem('baseUrl')
        this.seriesid = JSON.parse(this.$route.query.id)
        this.querytokenaddress = this.$route.query.tokenAddress
        this.queryListData()
    },
    methods: {
        goPage() {
            this.$router.go(-1)
        },
        addRound() {
            this.$router.push({ path: '/add', query: { id: this.seriesid, tokenAddress: this.querytokenaddress } })
        },
        queryListData() {
           if (this.mapState.chainId) {
                wallet.getWalletInfo().then((info) => {
                    let admin = info.address.toLowerCase();
                    let network = info.network;
                    let params = {
                        admin: admin,
                        seriesid: this.seriesid,
                        network: network,
                        tokenaddress: this.querytokenaddress
                    }
                    this.$axios.get(this.baseUrlData + this.$api.post_addroundList, params).then(res => {
                        this.roundsList = res.result
                    })
                })
            }
        },
    },
};
</script>
<style lang="scss" src='../../../assets/css/addRounds.scss' scoped></style>